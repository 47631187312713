<template>
  <div>
    <router-link to="/accounts" class="btn btn-sucsess">戻る</router-link>
    <h1>アカウント編集</h1>
    <div>
      <transition name="fade">
        <div class="alert alert-success" v-if="bSuccess">
          更新成功しました
        </div>
      </transition>
      <div class="mb-3 w-50">
        <label class="form-label">管理者情報編集</label>
        <div class="input-group">
          <router-link :to="'/contact/edit/'+account.Id" class="btn btn-primary">管理者情報編集</router-link>
        </div>
      </div>

      <form @submit.prevent="updateAccount">
        <div class="mb-3 w-50">
          <label class="form-label">アカウントID</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="account.Id" disabled>
          </div>
        </div>
        <div class="mb-3 w-50">
          <label for="projName" class="form-label">プロジェクト名</label>
          <div class="input-group">
            <input type="text" class="form-control" id="projName" v-model="account.Name" disabled>
          </div>
          <div id="projNameHelp" class="form-text">プロジェクト名の変更はAWS側で変更してから、最新情報取得を行って同期してください。</div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">プロジェクトの内容</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="account.Description">
          </div>
        </div>
        <div class="mb-3">
          <label for="button" class="form-label">ボタン</label>

          <input type="text" class="form-control" id="button">
        </div>
        <button type="submit" class="btn btn-primary">更新</button>
      </form>
    </div>
  </div>
</template>

<script>
import { API,graphqlOperation } from 'aws-amplify';
import { listAccounts } from '@/graphql/queries.ts';
import { updateAccounts } from '@/graphql/mutations.ts';

export default {
  name: "AccountEdit",
  data(){
    return {
      account: {},
      bSuccess: false
    }
  },
  created(){ // 初期化時に実行する内容
    this.getAccountInfo(this.$route.params.accountId);
  },
  methods: { // 関数定義
    async getAccountInfo(accountId){
      // フィルタ条件
      const filter = {
        Id: {
          'eq' : accountId
        }
      }

      // GraphQlから取得
      API.graphql(graphqlOperation(listAccounts, { filter: filter }))
      .then(response => {
          this.account = response.data.listAccounts.items[0];
          console.log(this.account);
      }).catch(err => {
          console.error('listAccounts',err)
      });

      // $route.params.accountId
    },

    updateAccount: function(){
      this.bSuccess = true;

      let update = this.account;
      delete update.updatedAt;
      delete update.createdAt;
      console.log(this.account);
      

      API.graphql(graphqlOperation(updateAccounts, {input: update}))
      .then(resUpdate => {
          console.log(resUpdate);
      })
      .catch(errorUpdate => {
          console.error(errorUpdate);
      });
    }
  }
};
</script>

<style>
/* フェードイン・フェードアウト中のスタイル */
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
/* フェードイン開始時・フェードアウト終了時のスタイル */
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>