<template>
  <div v-if="isSignedIn">
    <NaviBar />
    <!-- <amplify-greetings></amplify-greetings> -->
  </div>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
import NaviBar from './components/NaviBar.vue';
export default {
  components: {
    NaviBar,
  },
  computed: {
    isSignedIn() {
      return this.$store.state.user !== null;
    },
  },
};
</script>

  
<!--
// <template>
//     <div id="app">
//       <NaviBar />
//       <div class="container">
//         <router-view/>

//         <amplify-authenticator></amplify-authenticator>
//         <div class="row row-cols-1 row-cols-md-3 g-4">
//           <AccountsList account-name="aaaaa" />
//           <AccountsList account-name="bbbb" />
//           <AccountsList account-name="cccc" />
//           <AccountsList account-name="dddddddd" />
//           <AccountsList account-name="あいうえお" />
//           <AccountsList account-name="テストです" />
//           <AccountsList account-name="さしすせそ" />
//           <AccountsList account-name="たちつてと" />
//         </div>

//         <BootstrapTest />
//         <HelloWorld />
//         <h1>Todo App</h1>
//         <input type="text" v-model="name" placeholder="Todo name">
//         <input type="text" v-model="description" placeholder="Todo description">
//         <button v-on:click="createTodo">Create Todo</button>
//         <div v-for="item in todos" :key="item.id">
//           <h3>{{ item.name }}</h3>
//           <p>{{ item.description }}</p>
//         </div>
//       </div>
//     </div>
//     <amplify-sign-out></amplify-sign-out>
// </template>
-->

<!-- <script>
import { API } from 'aws-amplify';
import { createTodo } from './graphql/mutations.ts';
import { listTodos } from './graphql/queries.ts';
import { onCreateTodo } from './graphql/subscriptions.ts';
import AccountsList from './components/AccountsList.vue';
import HelloWorld from './components/HelloWorld.vue';
import BootstrapTest from './components/BootstrapTest.vue';
 
export default {
  name: 'App',
  components: {
    NaviBar,
    AccountsList,
    BootstrapTest,
    HelloWorld
  },
  data() {
    return {
      name: '',
      description: '',
      todos: []
    }
  },
  created(){
    this.getTodos();
    this.subscribe();
  },
  methods: {
    async createTodo() {
      const { name, description } = this;
      if (!name || !description) return;
      const todo = { name, description };
      this.todos = [...this.todos, todo];
      await API.graphql({
        query: createTodo,
        variables: {input: todo},
      });
      this.name = '';
      this.description = '';
    },
    async getTodos() {
      const todos = await API.graphql({
        query: listTodos
      });
      this.todos = todos.data.listTodos.items;
    },
    subscribe() {
      API.graphql({ query: onCreateTodo })
        .subscribe({
          next: (eventData) => {
            let todo = eventData.value.data.onCreateTodo;
            if (this.todos.some(item => item.name === todo.name)) return; // remove duplications
            this.todos = [...this.todos, todo];
          }
        });
    }
  }
}
</script>

<style>
#app {
 font-family: Avenir, Helvetica, Arial, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 color: #2c3e50;
}
</style> -->