/**
 * ルーティング定義
 */

import { createRouter, createWebHistory } from "vue-router";

import store from "@/store/index.js";
import Auth from "@aws-amplify/auth";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import SignIn from "../views/SignIn.vue";
import AccountsView from "../views/accounts/View.vue";
import AccountCreate from "../views/accounts/Create.vue";
import AccountEdit from "../views/accounts/Edit.vue";
import AccountDetail from "../views/accounts/Detail.vue";
import ContactEdit from "../views/AlternateContact/Edit.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "AccountsView" },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/accounts",
    name: "AccountsView",
    component: AccountsView,
    meta: { requireAuth: true },
  },
  {
    path: "/accounts/create",
    name: "AccountCreate",
    component: AccountCreate,
    meta: { requireAuth: true },
  },
  {
    path: "/accounts/edit/:accountId",
    name: "AccountEdit",
    component: AccountEdit,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/accounts/:accountId",
    name: "AccountDetail",
    component: AccountDetail,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/contact/edit/:accountId",
    name: "ContactEdit",
    component: ContactEdit,
    props: true,
    meta: { requireAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// ログインしているユーザーを取得してStoreに登録
function getAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        return data;
      }
    })
    .catch((e) => {
      console.error(e);
      store.commit("setUser", null);
      return null;
    });
}

// 画面遷移するたびに呼ばれる処理
let user;
router.beforeResolve(async (to, from, next) => {
  // ユーザー情報を取得
  user = await getAuthenticatedUser();

  // ログイン状態でサインインページに来たらアカウント一覧ページを返す
  if(to.name == "SignIn" && user){
    return next({name: "AccountsList" });
  }

  // ログインが必要な指定だけどユーザー情報が無い場合サインインページを返す
  if (to.matched.some((record) => record.meta.requireAuth) && !user){
    return next({ name: "SignIn" });
  }
  return next();
});

// サインイン状態の変更イベント
onAuthUIStateChange((authState, authData) => {
  // サインインした時
  if (authState === AuthState.SignedIn && authData) {
    // アカウント一覧ページへ移動
    router.push({ name: "AccountsList" });
  }

  // サインアウトした時
  if (authState === AuthState.SignedOut) {
    // サインインページへ移動
    router.push({ name: "SignIn" });
  }
});

export default router;