<template>
  <div>
    <authenticator :social-providers="['google']" :hide-sign-up="true">
      <template v-slot:header>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <h1>ログイン</h1>
        </div>
      </template>

      <template v-slot:sign-in-header>
        <h5
          class="amplify-heading"
          style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
        >
          Google Workspaceアカウントでログインしてください。
        </h5>
      </template>
            
      <template v-slot:footer>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
            ©Traffic Sim Co., Ltd. All Rights Reserved.
          </p>
        </div>
      </template>
                  
      <button @click="signOut">Sign Out</button>
    </authenticator>
  </div>
</template>

<script>
import { Authenticator } from '@aws-amplify/ui-vue';

export default {
  name: "SignIn",
  components: {
    Authenticator
  },
  data() {
    return {
    }
  },
  created(){
  },
  methods: {
  }
}


</script>