<template>
  <div>
    <router-link to="/accounts" class="btn btn-secondary">戻る</router-link>
    <h1>新規AWSアカウント作成</h1>
    <div>
      <transition name="fade">
        <div class="alert" v-bind:class="{'alert-secondary': bCreateing, 'alert-success': !bCreateing && bSuccess, 'alert-danger': !bCreateing && !bSuccess }" v-if="bMessage">
          <span class="m-2" v-if="bCreateing">
              <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
              </div>
          </span>
          {{createStatus}}
        </div>
      </transition>
      <form @submit.prevent="submitForm">
        <div class="mb-3 w-50">
          <label for="type" class="form-label">種別</label>
          <select id="type" class="form-select" v-model="selectedType" @change="changeSelectType">
            <option 
              v-for="item in projTypes" 
              :value="item.id" 
              :key="item.id"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="mb-3 w-50" v-if="isProd">
          <label for="customerName" class="form-label">お客様名(略称、半角英数のみ)</label>
          <div class="input-group">
            <input type="text" class="form-control" pattern="^[0-9a-zA-Z]+$" id="customerName" name="CustomerName" v-model="customerName" @keyup="changeAccountName">
          </div>
        </div>
        <div class="mb-3 w-50" v-if="isProd">
          <label for="devAccount" class="form-label">開発環境アカウント</label>
          <div class="input-group">
            <select class="form-select" v-model="devAccountName" @change="changeDevAccountSelect">
              <option v-for="account in devAccounts" :key="account.Id" class="col mb-3">
                {{account.Name}}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 w-50" v-if="isProd">
          <label for="usage" class="form-label">用途(同一のお客様に同一のシステムが複数ある場合の区別用。半角英数のみ)</label>
          <div class="input-group">
            <input type="text" class="form-control" pattern="^[0-9a-zA-Z]+$" id="usage" name="Name" v-model="usage" @keyup="changeAccountName">
          </div>
        </div>
        <div class="mb-3 w-50">
          <label for="projName" class="form-label">AWSアカウント名<span v-if="isProd">(自動生成)</span></label>
          <div class="input-group">
            <span class="input-group-text" v-if="isProd && customerName!=''">{{customerName}}-</span>
            <input type="text" class="form-control" id="projName" name="Name" pattern="^[0-9a-zA-Z]+$" v-model="accountname" @keyup="changeAccountName" @change="changeAccountName" :disabled="isProd" required>
            <span class="input-group-text" v-if="isProd && usage">-{{usage}}</span>
            <span class="input-group-text" v-if='selectedType!=""'>-{{selectedType}}</span>
          </div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">プロジェクトの内容(アカウント一覧に表示される説明文。日本語可)</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="description">
          </div>
        </div>
        <div class="mb-3 w-50">
          <label for="projName" class="form-label">メールアドレス(自動生成)</label>
          <div class="input-group">
            <span class="input-group-text">aws+</span><input type="text" class="form-control" id="projName" name="Name" v-model="email" disabled><span class="input-group-text">＠trafficsim.co.jp</span>
          </div>
        </div>
        <div class="mb-3 w-50">
          <label for="ou" class="form-label">組織単位 (OU) </label>
          <div class="input-group">
            <select class="form-select" v-model="ouId">
              <option v-for="OrganizationalUnit in OrganizationalUnits" :key="OrganizationalUnit.Id" class="col mb-3" :value="OrganizationalUnit.Id">
                {{OrganizationalUnit.Name}}
              </option>
            </select>
          </div>
        </div>
        <!-- <div class="mb-3" v-if="isProd">
          <label for="member" class="form-label">メンバー</label>
          <input type="text" class="form-control" id="member">
        </div>
        <div class="mb-3" v-if="isProd">
          <label for="service" class="form-label">利用するサービス</label>
          <input type="text" class="form-control" id="service">
        </div> -->
        <button type="submit" class="btn btn-primary">作成</button>
      </form>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import { graphqlOperation } from 'aws-amplify';
import { listAccounts } from '@/graphql/queries.ts';
import { createAccounts } from '@/graphql/mutations.ts';

export default {
  name: "AccountCreate",
  data() {
    return {
      projTypes: [
        {  id: "dev", label: "開発環境" },
        {  id: "prod", label: "本番環境" },
        {  id: "demo", label: "デモ環境" },
        {  id: "",     label: "社内向け" },
      ],
      selectedType: "dev",
      accountname: "",
      email: "",
      devAccounts: [],
      devAccountName: "",
      customerName: "",
      usage: "",
      description: "",
      ouId: "", // 組織単位(OU)のID
      OrganizationalUnits: [],
      bMessage: false, // アラートメッセージ表示フラグ
      createStatus: "", // アラートに表示するメッセージ
      bCreateing: false, // 作成中フラグ
      bSuccess: true,   // 作成結果フラグ
    }
  },
  computed: {
    isProd() {
      return this.selectedType == "prod";
    }
  },
  created(){ // 初期化時に実行する内容
    this.getDevAccountList();
    this.getOrganizationalUnits();
  },
  methods:{
    getAccountName: function () {
      let customerName = "";
      if(this.customerName != "" && this.selectedType == "prod") {
        customerName = this.customerName + "-";
      }

      let usage = "";
      if(this.usage != "" && this.selectedType == "prod") {
        usage = "-" + this.usage;
      }

      let accountName = customerName + this.accountname + usage + "-" + this.selectedType;
      return accountName;
    },

    submitForm: function () {
      this.bMessage = true;
      this.bCreateing = true;
      this.createStatus = "作成開始しました。そのままお待ちください。";

      // アカウント名を組み立てる
      let accountName = this.getAccountName();

      // AWSにアカウント作成要求を投げる
      API.post('accounts','/accounts',{
          headers: {}, // ヘッダー
          body: {  // パラメータ
            Name : accountName,
            Email : "aws+" + this.email + "@trafficsim.co.jp"
          },
      }).then(response => {
        // 作成開始した
        console.log(response);
        this.createStatus = "作成開始しました。そのままお待ちください。 リクエストID:" + response.CreateAccountStatus.Id;
        this.checkCreateStatus(response.CreateAccountStatus.Id);
      }).catch(error => {
        console.log(error.response);
        this.bSuccess = false;
        this.bCreateing = false;
        this.createStatus = "作成失敗しました。 API呼び出しに失敗:"+error.response;
      });
    },

    // アカウントが作成完了するまでチェックする
    checkCreateStatus: function(CreateAccountStatusId){
        this.bMessage = true;
        API.get('accounts','/describeCreateAccountStatus/', {
          headers: {}, // ヘッダー
          queryStringParameters: {  // パラメータ
            "Id": CreateAccountStatusId
          },
        }).then(response => {
            if(response.CreateAccountStatus.State == "IN_PROGRESS"){
              // まだ作成中
              this.checkCreateStatus(CreateAccountStatusId);
            } else if(response.CreateAccountStatus.State == "FAILED"){
              // 作成失敗
              this.bSuccess = false;
              this.bCreateing = false;
              this.createStatus = "作成失敗しました。 ";
              switch(response.CreateAccountStatus.FailureReason){
                case "ACCOUNT_LIMIT_EXCEEDED":
                  this.createStatus = this.createStatus + "組織内のアカウント数の制限に達したため、アカウントを作成できませんでした。";
                  break;
                case "CONCURRENT_ACCOUNT_MODIFICATION":
                  this.createStatus = this.createStatus + "同じ情報を使用してリクエストを送信しました。";
                  break;
                case "EMAIL_ALREADY_EXISTS":
                  this.createStatus = this.createStatus + "そのメールアドレスを持つ別のアマゾンウェブサービスアカウントがすでに存在するため、アカウントを作成できませんでした。"
                  break;
                case "FAILED_BUSINESS_VALIDATION":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントは、ビジネスライセンスの検証を受け取ることができませんでした。"
                  break;
                case "GOVCLOUD_ACCOUNT_ALREADY_EXISTS":
                  this.createStatus = this.createStatus + "アマゾンウェブサービスGovCloud（US）リージョンのアカウントは、このリージョンにすでにそのメールアドレスのアカウントが含まれているため、作成できませんでした。"
                  break;
                case "IDENTITY_INVALID_BUSINESS_VALIDATION":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントは、有効なIDデータがないため、ビジネスライセンスの検証を完了できません。"
                  break;
                case "INVALID_ADDRESS":
                  this.createStatus = this.createStatus + "指定したアドレスが無効なため、アカウントを作成できませんでした。"
                  break;
                case "INVALID_EMAIL":
                  this.createStatus = this.createStatus + "指定したメールアドレスが無効なため、アカウントを作成できませんでした。"
                  break;
                case "INVALID_PAYMENT_INSTRUMENT":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントには、アカウントに関連付けられたサポートされている支払い方法がありません。アマゾンウェブサービスは、ロシアまたはベラルーシの金融機関が発行したカードをサポートしていません。詳細については、「アマゾンウェブサービスの支払いの管理」を参照してください。"
                  break;
                case "INTERNAL_FAILURE":
                  this.createStatus = this.createStatus + "内部障害のため、アカウントを作成できませんでした。あとでもう一度試してみてください。問題が解決しない場合は、アマゾンウェブサービスのカスタマーサポートに連絡してください。"
                  break;
                case "MISSING_BUSINESS_VALIDATION":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントがビジネス検証を受けていません。"
                  break;
                case "MISSING_PAYMENT_INSTRUMENT":
                  this.createStatus = this.createStatus + "クレジットカードなどの有効な支払い方法を使用して管理アカウントを構成する必要があります。"
                  break;
                case "PENDING_BUSINESS_VALIDATION":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントは、ビジネスライセンスの検証を完了中です。"
                  break;
                case "UNKNOWN_BUSINESS_VALIDATION":
                  this.createStatus = this.createStatus + "組織を所有するアマゾンウェブサービスアカウントには、ビジネスライセンスの検証に関する不明な問題があります。"
                  break;
                default:
                  this.createStatus = this.createStatus + response.CreateAccountStatus.FailureReason;
                  break;
              }

            } else if(response.CreateAccountStatus.State == "SUCCEEDED"){
              // 作成完了
              this.createStatus = "組織単位(OU)に移動中。そのままお待ちください。 AWSアカウントID:"+response.CreateAccountStatus.AccountId;
              this.moveOrganizationUnit(response.CreateAccountStatus.AccountId);
            }
        }).catch(error => {
          console.log(error);
          this.bSuccess = false;
          this.bCreateing = false;
          this.createStatus = "状態取得に失敗。作成状態はAWSコンソールでご確認ください。";
        });
    },

    moveOrganizationUnit: function(accountId){
      // AWSにアカウントのOU移動要求をする
      API.post('accounts','/moveAccount',{
          headers: {}, // ヘッダー
          body: {  // パラメータ
            AccountId : accountId,
            DestinationParentId: this.ouId
          },
      }).then(response => {
        // 作成開始した
        console.log(response);
        this.createStatus = "データベースに登録中。そのままお待ちください。AWSアカウントID:" + accountId;
        this.addDatabase(accountId);
      }).catch(error => {
        console.log(error.response);
        this.bSuccess = false;
        this.bCreateing = false;
        this.createStatus = "OUの移動に失敗しました。 API呼び出しに失敗:"+error.response;
      });

    },

    addDatabase: function(accountId){
      var accountInfo = {
        "Id": accountId,
        "Arn": "未取得",
        "Name": this.getAccountName(),
        "Description": this.description,
        "Email": "aws+" + this.email + "@trafficsim.co.jp",
        "JoinedMethod": "未取得",
        "JoinedTimestamp": "未取得",
        "Status": "ACTIVE"
      };
      console.log(accountInfo);
      API.graphql(graphqlOperation(createAccounts, {input: accountInfo}))
      .then(resCreate => {
          console.log(resCreate);
          this.bSuccess = true;
          this.bCreateing = false;
          this.createStatus = "作成完了しました。AWSアカウントID:"+accountId;
      })
      .catch(errorCreate => {
          console.error(errorCreate);
          this.bCreateing = false;
          this.bSuccess = false;
          this.createStatus = "データベースの登録失敗。再取得をお試しください。AWSアカウントID:"+accountId;
      });
    },

    async getDevAccountList(){
      // フィルタ条件 -devを含む
      const filter = {
        Name: {
          'contains' : '-dev'
        }
      }

      // GraphQlから取得
      API.graphql(graphqlOperation(listAccounts, { filter: filter }))
      .then(response => {
          this.devAccounts = response.data.listAccounts.items;
          console.log(this.devAccounts);
      }).catch(err => {
          console.error('listAccounts',err)
      });

      // $route.params.accountId
    },

    // 組織単位(OU)の一覧を取得
    getOrganizationalUnits: function(){
        API.get('accounts','/moveAccount/', {
          headers: {}, // ヘッダー
          queryStringParameters: {},  // パラメータ
        }).then(response => {
          this.OrganizationalUnits = response.OrganizationalUnits;
        });
    },

    // 種別変更イベント
    changeSelectType: function(){
      let typeString = "";
      if(this.selectedType != ""){
        typeString = "-" + this.selectedType.toLowerCase();
      }
      if(this.selectedType != "prod"){
        this.email = this.accountname.toLowerCase() + typeString;
      }else{
        this.changeDevAccountSelect();
      }
    },

    // 開発環境アカウントの選択変更イベント
    changeDevAccountSelect: function(){
      // 後ろの4文字(-dev)を消してアカウント名にセットする
      this.accountname = this.devAccountName.slice(0,-4);
      let typeString = "";
      if(this.selectedType != ""){
        typeString = "-" + this.selectedType.toLowerCase();
      }
      this.email = this.accountname.toLowerCase() + typeString;
    },

    // アカウント名変更イベント
    changeAccountName: function(){
      let customerName = "";
      if(this.customerName != ""){
        customerName = this.customerName.toLowerCase() + "-";
      }

      let usage = "";
      if(this.usage != ""){
        usage = "-" +this.usage.toLowerCase();
      }

      let typeString = "";
      if(this.selectedType != ""){
        typeString = "-" + this.selectedType.toLowerCase();
      }

      this.email = customerName + this.accountname.toLowerCase() + usage + typeString;
    }
  }
};

</script>