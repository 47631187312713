<template>
  <div>
    <h1>{{account.Name}}アカウント管理者情報登録</h1>
    <div>
      <transition name="fade">
        <div class="alert alert-success" v-if="bSuccess">
          更新成功しました
        </div>
      </transition>
      <button @click="getContactFromAWS" class="btn btn-primary" :disabled="bLoading">AWSから情報取得</button>
      <span class="m-2" v-if="bLoading">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </span>

      <form @submit.prevent="updateAccount">
        <div class="mt-3 mb-3 w-50">
          <label class="form-label">アカウントID</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="contact.accountId" disabled>
          </div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">メールアドレス</label>
          <div class="input-group">
            <input type="email" class="form-control" v-model="contact.EmailAddress">
          </div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">フルネーム</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="contact.Name">
          </div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">電話番号</label>
          <div class="input-group">
            <input type="tel" class="form-control" v-model="contact.PhoneNumber">
          </div>
          <div id="TelHelp" class="form-text">国番号(+81)をつけて、先頭の0を消してください。 会社代表番号の場合の例) +81525265162</div>
        </div>
        <div class="mb-3 w-50">
          <label class="form-label">役職</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="contact.Title">
          </div>
        </div>
        <button type="submit" class="btn btn-primary">更新</button> <router-link :to="'/accounts/edit/'+contact.accountId" class="btn btn-secondary">キャンセル</router-link>
      </form>
    </div>
  </div>
</template>

<script>
import { API,graphqlOperation } from 'aws-amplify';
import { listAccounts } from '@/graphql/queries.ts';
import { updateAccounts } from '@/graphql/mutations.ts';

export default {
  name: "AccountEdit",
  data(){
    return {
      contact: {},
      account: {},
      bSuccess: false,
      bLoading: false
    }
  },
  created(){ // 初期化時に実行する内容
    this.getAccountInfo(this.$route.params.accountId);
  },
  methods: { // 関数定義
    async getAccountInfo(accountId){
      // フィルタ条件
      const filter = {
        Id: {
          'eq' : accountId
        }
      }

      // GraphQlから取得
      API.graphql(graphqlOperation(listAccounts, { filter: filter }))
      .then(response => {
          this.account = response.data.listAccounts.items[0];
          this.contact.accountId = this.account.Id;
          if(this.account.Contact){
            this.contact.EmailAddress = this.account.Contact.EmailAddress;
            this.contact.Name = this.account.Contact.Name;
            this.contact.PhoneNumber = this.account.Contact.PhoneNumber;
            this.contact.Title = this.account.Contact.Title;
          }
          console.log(this.account);
      }).catch(err => {
          console.error('listAccounts',err)
      });

      // $route.params.accountId
    },

    updateAccount: function(){
      this.bSuccess = true;

      let update = this.account;
      update.Contact = {
        EmailAddress : this.contact.EmailAddress, // メールアドレス
        Name : this.contact.Name,                 // フルネーム
        PhoneNumber : this.contact.PhoneNumber,   // 電話番号
        Title : this.contact.Title               // 役職
      }
      delete update.updatedAt;
      delete update.createdAt;
      console.log(update);

      // GraphQLを更新
      API.graphql(graphqlOperation(updateAccounts, {input: update}))
      .then(resUpdate => {
          console.log(resUpdate);
      })
      .catch(errorUpdate => {
          console.error(errorUpdate);
      });

      // AWSを更新
      API.post('accounts','/AlternateContact',{
          headers: {}, // ヘッダー
          response: true, // response.dataだけでなく、Axiosレスポンスオブジェクト全体を返すフラグ
          body: {  // パラメータ
            accountId    : this.contact.accountId,     // AWSアカウントID
            EmailAddress : this.contact.EmailAddress,  // メールアドレス
            Name         : this.contact.Name,          // フルネーム
            PhoneNumber  : this.contact.PhoneNumber,   // 電話番号
            Title        : this.contact.Title,         // 役職                    
          },
      })
      .then(response => {
        console.log(response);   
      })
      .catch(error => {
        console.log(error);
      })


    },

    getContactFromAWS: function(){
      this.bLoading = true;
      API.get('accounts','/AlternateContact',{
          headers: {}, // ヘッダー
          response: true, // response.dataだけでなく、Axiosレスポンスオブジェクト全体を返すフラグ
          queryStringParameters: {  // パラメータ
            accountId : this.contact.accountId,
          },
      })
      .then(response => {
        console.log(response.data);
        this.contact.EmailAddress = response.data.AlternateContact.EmailAddress;  // メールアドレス
        this.contact.Name = response.data.AlternateContact.Name;                  // フルネーム
        this.contact.PhoneNumber = response.data.AlternateContact.PhoneNumber;    // 電話番号
        this.contact.Title = response.data.AlternateContact.Title;                // 役職        
        this.bLoading = false;
      })
      .catch(error => {
        alert("設定されていません");
        console.log(error);
        this.bLoading = false;
      })
    },

    updateContactFromAWS: function(){
      API.post('accounts','/AlternateContact',{
          headers: {}, // ヘッダー
          body: {  // パラメータ
            accountId : this.contact.accountId,
            EmailAddress: this.contact.EmailAddress,  // メールアドレス
            Name: this.contact.Name,                  // フルネーム
            PhoneNumber: this.contact.PhoneNumber,    // 電話番号
            Title: this.contact.Title,                // 役職
          },
      })
    }
  }
};
</script>

<style>
/* フェードイン・フェードアウト中のスタイル */
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
/* フェードイン開始時・フェードアウト終了時のスタイル */
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>