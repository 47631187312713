<template>
  <div>
    <h1>AWSアカウント一覧</h1>
    <router-link to="/accounts/create" class="btn btn-primary">新規AWSアカウント作成</router-link>
    <div class="g-4 mt-3">
      <AccountsList account-name="aaaaa" />
    </div>
  </div>
</template>

<script>
import AccountsList from '../../components/AccountsList.vue';

export default {
  name: "AccountsView",
  components: {
    AccountsList,
  }
};
</script>