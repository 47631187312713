<template>
  <div>
    <h1>アカウント詳細</h1>
  </div>
</template>

<script>
export default {
  name: "AccountDetail",
};
</script>