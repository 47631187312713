/**
 * Store - 全コンポーネント共通のデータ管理
 */
import Vuex from 'vuex'

// Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 全コンポーネント共通の変数
        //   {{ $store.state.*** }} でアクセス出来る
        //   $store.state.***.filter(条件式) で絞り込みもできる。
        user: null,
    },
    mutations: { 
        // 単純にステートの値の更新をするメソッド
        // $store.commit('メソッド名',引数) で、どこからでも
        // 呼び出し可能だけど、actions経由で呼びだすのが推奨。
        setUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        // 処理をしてからミューテーションを呼び出して値を更新するメソッド
        // $store.dispatch('メソッド名') で、どこからでも呼び出せる。
        setUser(context,user) {
            context.commit('setUser',user);
        }
    },
    modules: {
    }
})