<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-4">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">AWSアカウント管理システム</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/accounts">AWSアカウント一覧</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/accounts/create">新規AWSアカウント作成</router-link>
                    </li>
                </ul>
            </div>
            <ul class="nav justify-content-end">
                <li class="nav-item">
                    <button class="btn btn-danger" @click="signOut">ログアウト</button>
                </li>
<!-- 
                <li class="nav-item">
                    <amplify-greetings></amplify-greetings>
                    <a class="nav-link active" aria-current="page" href="#">{{user_name}}</a>
                </li> -->
            </ul>
        </div>
    </nav>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: "NaviBar",
  components: {
  },
  methods: {
      signOut(){
          return Auth.signOut()
            .then(data => {
                this.$store.dispatch('setUser',null);
                return data;
            })
            .catch(err => {
                console.log(err);
                return err;
            });
      }
  }
};
</script>
