/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccounts = /* GraphQL */ `
  query GetAccounts($id: ID!) {
    getAccounts(id: $id) {
      Id
      Arn
      Name
      Description
      Email
      JoinedMethod
      JoinedTimestamp
      Status
      AdminName
      Link {
        text
        url
      }
      Contact {
        EmailAddress
        Name
        PhoneNumber
        Title
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        Arn
        Name
        Description
        Email
        JoinedMethod
        JoinedTimestamp
        Status
        AdminName
        Link {
          text
          url
        }
        Contact {
          EmailAddress
          Name
          PhoneNumber
          Title
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
