<template>
    <button @click="getAccountsListFromAWSAPI" class="btn btn-primary col-12 col-md-3 mb-3" :disabled="bLoading">AWSから最新のアカウント一覧を取得</button>
    <span class="m-2" v-if="bLoading">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </span>
    <br>
    検索 <input type="search" v-model="keyword" @keyup="filterAccountList" @change="filterAccountList" class="form-control mb-3">

    <draggable 
        class="row row-cols-1 row-cols-md-2 row-cols-xl-3 row-xols-xxl-6"
        v-model="filterdAccounts" 
        v-bind="dragOptions" 
        group="people" 
        @start="drag=true" 
        @end="drag=false" 
        item-key="Id">
        <template #item="{element}">
            <div class="col mb-3">
                <div :class="[element.Status=='ACTIVE' ? 'card text-dark bg-light h-100 shadow' : 'card text-white bg-secondary h-100 shadow']">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{element.Name}}
                            <router-link :to="`/accounts/edit/`+ element.Id" class="btn btn-text position-absolute top-0 end-0"><i class="bi bi-gear-wide-connected h4"></i></router-link>                        
                        </h5>
                        <h6 class="card-subtitle">{{element.Description}}</h6>
                        <p class="card-text">
                            <table :class="[element.Status=='ACTIVE' ? 'table table-sm table-borderless text-dark' : 'table table-sm table-borderless text-white']">
                                <tr :class="[element.Contact ? 'text-body' : 'text-danger']">
                                    <td><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'user-gear' }" title="管理者" /></td>
                                    <td>{{element.Contact ? `${element.Contact.Name}` : '設定されていません' }}</td>
                                </tr>
                                <tr>
                                    <td><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'id-card' }" title="アカウントID"/></td>
                                    <td>{{element.Id}}</td>
                                </tr>
                                <tr>
                                    <td><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }" title="メールアドレス"/></td>
                                    <td>{{element.Email}}</td>
                                </tr>
                                <tr>
                                    <td><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'clock' }" title="作成日時"/></td>
                                    <td>{{element.JoinedTimestamp}}</td>
                                </tr>
                                <tr>
                                    <td><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'bolt' }" title="ステータス"/></td>
                                    <td>{{element.Status}}</td>
                                </tr>
                            </table>
                        </p>
                    </div>
                    <ul class="list-group list-group-flush">
                        <a v-if="element.Status=='ACTIVE'" :href="`https://signin.aws.amazon.com/switchrole?account=` + element.Id + `&roleName=OrganizationAccountAccessRole&displayName=` + element.Name" class="list-group-item list-group-item-action" target="_blank"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'aws' }" /> スイッチロール</a>
                        <a v-for="link in element.Link" :key="link.Name" class="list-group-item list-group-item list-group-item-action" href={{link.url}}>{{link.name}}</a>
                    </ul>
                </div>
            </div>
        </template>
    </draggable>
</template>

<script>
import { API } from 'aws-amplify';
import { graphqlOperation } from 'aws-amplify';
import { listAccounts } from '@/graphql/queries.ts';
import { createAccounts, updateAccounts } from '@/graphql/mutations.ts';
import draggable from 'vuedraggable';

export default {
    name: 'AccountsList',
    components:{
       draggable
    },
    props:{
        accountName: String
    },
    data() {
        return  {
            keyword: '',
            accounts: [],
            filterdAccounts: [],
            bLoading: false,
            drag: false
        }
    },
    created(){ // 初期化時に実行する内容
        this.getAccountsList();
    },
    methods: { // 関数定義
        async getAccountsList(){
            console.log("GraphQLからアカウント一覧を取得");

            // フィルタ条件
            const filter = {
                Status: {
                    'ne' : "SUSPENDED"
                }
            }

            API.graphql(graphqlOperation(listAccounts, { filter: filter }))
            .then(response => {
                this.accounts = response.data.listAccounts.items;
                this.filterAccountList();
                console.log(this.accounts);
            }).catch(err => {
                console.error('listAccounts',err)
            });
        },

        async getAccountsListFromAWSAPI(){
            this.bLoading = true;
            console.log("AWS SDKから最新のアカウント一覧を取得");
            API.get('accounts','/accounts',{
                headers: {}, // ヘッダー
                response: true, // response.dataだけでなく、Axiosレスポンスオブジェクト全体を返すフラグ
                queryStringParameters: {  // パラメータ
                },
            })
            .then(response => {
                console.log(response.data);
                response.data.forEach(res => {
                    let isNew = true;

                    // 既存のGraphQLから探す
                    this.accounts.forEach(account => {
                        // 同じAWSアカウント見つけた
                        if(res.Id == account.Id){
                            isNew = false;
                            let isUpdate = false;
                            
                            if(account.Arn != res.Arn){ 
                                account.Arn = res.Arn;
                                isUpdate = true;
                            }
                            if(account.Email != res.Email){ 
                                account.Email = res.Email;
                                isUpdate=true;
                            }
                            if(account.JoinedMethod != res.JoinedMethod){ 
                                account.JoinedMethod = res.JoinedMethod;
                                isUpdate=true;
                            }
                            if(account.JoinedTimestamp != res.JoinedTimestamp){ 
                                account.JoinedTimestamp = res.JoinedTimestamp;
                                isUpdate=true;
                            }
                            if(account.Name != res.Name){ 
                                account.Name = res.Name;
                                isUpdate=true;
                            }
                            if(account.Status != res.Status){ 
                                account.Status = res.Status;
                                isUpdate=true;
                            }

                            if(isUpdate){
                                delete account.updatedAt;
                                delete account.createdAt;
                                // 内容が更新されていたらGraphQLの内容を更新
                                console.log("GraphQLデータを更新 AWSアカウントID:" + account.Id + " " + account.Name);
                                API.graphql(graphqlOperation(updateAccounts, {input: account}))
                                .then(resCreate => {
                                    console.log(resCreate);
                                })
                                .catch(errorCreate => {
                                    console.error(errorCreate);
                                });
                            }else{
                                console.log("更新無し AWSアカウントID:" + account.Id + " " + account.Name);
                            }
                        }
                    });


                    if(isNew && res.Status == "ACTIVE"){
                        // GoraphQLにないAWSアカウントIDだったらGraphQLに新規登録
                        console.log("新規アカウントをGraphQLに追加 AWSアカウントID:" + res.Id);
                        API.graphql(graphqlOperation(createAccounts, {input: res}))
                        .then(resCreate => {
                            console.log(resCreate);
                        })
                        .catch(errorCreate => {
                            console.error(errorCreate);
                        });
                    }
                });
                this.bLoading = false;

                // this.accounts.forEach(account => {
                //     API.graphql(graphqlOperation(createAccounts, {input: account}))
                //     .then(resUpdate => {
                //         console.log(resUpdate);
                //     })
                //     .catch(errorUpdate => {
                //         console.error(errorUpdate);
                //     });
                // });
            })
            .catch(error => {
                console.log(error.response);
                this.bLoading = false;
            })
        },

        // keywordを含む物だけにする
        async filterAccountList(){
            this.filterdAccounts = [];
            for(var i in this.accounts) {
                var account = this.accounts[i];
                if((account.Name && account.Name.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) ||
                (account.Description && account.Description.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) ||
                (account.AdminName && account.AdminName.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) ||
                (account.Id && account.Id.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) ||
                (account.Email && account.Email.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)) {
                    this.filterdAccounts.push(account);
                }
            }
        }
    },
    computed: {

        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    }
}

</script>

<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>