import { createApp } from 'vue'
import App from './App.vue'
import AmplifyVue from '@aws-amplify/ui-vue';
import router from './router'
import store from './store'

import '@aws-amplify/ui-vue/styles.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

const { host } = window.location;

// prod,dev,localで複数のリダイレクトURLが設定されている場合、
// カンマで繋がって1個で入っているせいでredirect_mismatchエラーになるので、
// ドメインが一致するものだけをカンマ区切りで切り出して上書きする
if (aws_exports.oauth.redirectSignIn.includes(',')) {
  const filterHost = url => new URL(url).host === host;
  aws_exports.oauth.redirectSignIn = aws_exports.oauth.redirectSignIn
    .split(',')
    .filter(filterHost)
    .shift();
    aws_exports.oauth.redirectSignOut = aws_exports.oauth.redirectSignOut
    .split(',')
    .filter(filterHost)
    .shift();
}


Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});


/* add icons to the library */
library.add(fas, far, fab)

const app = createApp(App);
app.use(AmplifyVue);
app.use(store);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
