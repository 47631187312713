/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccounts = /* GraphQL */ `
  mutation CreateAccounts(
    $input: CreateAccountsInput!
    $condition: ModelAccountsConditionInput
  ) {
    createAccounts(input: $input, condition: $condition) {
      Id
      Arn
      Name
      Description
      Email
      JoinedMethod
      JoinedTimestamp
      Status
      AdminName
      Link {
        text
        url
      }
      Contact {
        EmailAddress
        Name
        PhoneNumber
        Title
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateAccounts = /* GraphQL */ `
  mutation UpdateAccounts(
    $input: UpdateAccountsInput!
    $condition: ModelAccountsConditionInput
  ) {
    updateAccounts(input: $input, condition: $condition) {
      Id
      Arn
      Name
      Description
      Email
      JoinedMethod
      JoinedTimestamp
      Status
      AdminName
      Link {
        text
        url
      }
      Contact {
        EmailAddress
        Name
        PhoneNumber
        Title
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccounts = /* GraphQL */ `
  mutation DeleteAccounts(
    $input: DeleteAccountsInput!
    $condition: ModelAccountsConditionInput
  ) {
    deleteAccounts(input: $input, condition: $condition) {
      Id
      Arn
      Name
      Description
      Email
      JoinedMethod
      JoinedTimestamp
      Status
      AdminName
      Link {
        text
        url
      }
      Contact {
        EmailAddress
        Name
        PhoneNumber
        Title
      }
      id
      createdAt
      updatedAt
    }
  }
`;
